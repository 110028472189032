<template>
  <div class="contents">
    <div v-if="item.grid && item.grid.colSpan" class="w-full px-2">
      <vue-input-group
        v-model="item.grid.colSpan"
        prop="colspan"
        name="colspan"
        label="Item Width"
        type="number"
        min="1"
        step="1"
        append="blocks"
      />
    </div>
    <div class="w-full px-2">
      <vue-input-group
        v-model="item.content.title"
        prop="title"
        name="title"
        label="Title"
      />
    </div>
    <div class="w-full px-2">
      <vue-input-group
        v-model="item.content.content"
        prop="content"
        name="content"
        label="Content"
        type="markdown"
        rows="10"
      />
    </div>
  </div>
</template>
<script>
export default {
  props: {
    item: {
      type: Object,
      required: true
    }
  }
}
</script>
